import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
<path d="M7342 7809 c-13 -5 -26 -18 -29 -29 -3 -12 -14 -20 -27 -20 -13 0
-58 -36 -116 -91 -52 -50 -120 -109 -150 -132 -30 -23 -67 -53 -81 -68 -42
-41 -56 -42 -89 -5 -28 30 -34 33 -79 28 -47 -4 -48 -4 -54 27 l-6 31 -85 0
c-78 0 -86 2 -104 25 -17 21 -28 25 -71 25 -39 0 -51 4 -51 15 0 11 -11 15
-40 15 -22 0 -40 5 -40 10 0 6 -18 10 -40 10 -29 0 -40 4 -40 15 0 11 -12 15
-50 15 -43 0 -50 3 -50 20 0 23 -1 24 -35 8 -14 -6 -25 -19 -25 -28 0 -11 -14
-21 -40 -29 -22 -6 -40 -18 -40 -25 0 -8 -10 -16 -22 -20 -13 -3 -48 -28 -78
-56 -30 -28 -65 -55 -77 -60 -16 -7 -20 -15 -16 -30 5 -18 2 -21 -13 -18 -20
4 -21 9 -10 44 4 15 2 24 -9 28 -10 4 -15 -1 -15 -14 0 -13 -5 -18 -15 -14
-11 5 -15 -1 -15 -20 0 -21 -5 -26 -24 -26 -30 0 -112 -79 -108 -103 3 -12 -1
-15 -13 -11 -21 8 -49 -27 -32 -39 8 -6 5 -7 -7 -3 -14 5 -65 -40 -227 -201
-115 -114 -209 -210 -209 -214 0 -4 13 -20 28 -36 16 -15 31 -33 33 -38 4 -10
29 -31 138 -112 31 -24 69 -53 84 -65 16 -13 41 -30 55 -38 15 -9 42 -26 61
-38 19 -11 81 -44 138 -73 57 -28 103 -55 103 -59 0 -11 -63 -35 -83 -32 -10
2 -27 -4 -38 -14 -17 -15 -20 -15 -43 0 -14 9 -34 16 -46 16 -11 0 -20 5 -20
10 0 6 -7 10 -16 10 -17 0 -137 61 -142 73 -2 4 -8 7 -13 7 -12 0 -113 62
-117 72 -2 4 -8 8 -13 8 -11 0 -92 60 -97 73 -2 4 -9 7 -16 7 -7 0 -60 44
-117 99 -156 147 -182 170 -191 165 -4 -3 -5 1 -2 9 3 8 -11 24 -37 41 -23 15
-38 22 -34 14 6 -10 5 -11 -6 0 -7 6 -22 12 -34 12 -11 0 -26 10 -34 21 -9 16
-15 18 -21 10 -11 -18 -150 -4 -150 15 0 11 -37 14 -200 14 l-200 0 0 30 0 30
-439 0 c-263 0 -441 4 -443 9 -1 5 -34 11 -73 13 -38 2 -73 8 -77 13 -5 6 -8
5 -8 -2 0 -10 -31 -13 -120 -13 -98 0 -120 -3 -120 -14 0 -20 -241 -28 -267
-8 -15 11 -14 12 8 12 20 0 21 2 9 11 -12 9 -11 9 5 4 11 -4 36 -8 55 -10 35
-2 36 -1 32 32 l-4 33 -114 0 c-94 0 -116 -3 -120 -15 -5 -13 -8 -13 -18 -2
-14 18 -40 27 -53 19 -10 -6 -17 -48 -14 -77 1 -5 27 -11 59 -13 63 -3 76 -10
66 -35 -8 -23 -44 -22 -44 1 0 11 -8 17 -24 17 -27 0 -26 -3 30 -63 41 -44 42
-45 84 -85 19 -19 339 -339 710 -712 728 -731 745 -747 835 -808 33 -22 62
-44 63 -49 2 -4 8 -8 13 -8 5 0 32 -15 61 -34 51 -32 221 -116 236 -116 4 0
18 -6 32 -13 14 -7 50 -21 80 -30 30 -10 64 -22 75 -27 31 -13 181 -42 265
-51 41 -4 107 -13 145 -19 70 -11 211 -5 230 10 6 4 46 10 90 14 111 8 168 16
180 27 5 5 19 9 32 9 12 0 32 4 45 9 13 5 43 15 68 22 52 15 217 86 275 119
22 12 58 32 80 43 22 12 42 24 45 27 3 3 17 11 32 19 15 8 36 21 45 30 10 9
45 36 78 60 72 52 1920 1901 1877 1876 -10 -5 -13 -2 -10 11 2 12 11 18 22 16
18 -3 56 24 56 40 0 17 -21 7 -30 -14 l-9 -23 0 22 c-1 15 9 28 29 38 17 9 28
20 25 25 -4 6 5 10 19 10 14 0 28 5 32 12 5 8 2 9 -9 5 -10 -3 -17 -2 -17 3 0
5 15 11 33 13 29 4 30 5 10 12 -26 9 -30 20 -8 29 8 3 15 12 15 21 0 8 8 15
19 15 10 0 21 9 24 20 3 11 3 20 1 20 -2 0 -13 -9 -24 -20 -26 -26 -45 -25
-64 2 -14 20 -25 22 -103 23 l-88 0 -3 28 c-3 27 -4 27 -83 27 -46 0 -79 4
-79 10 0 6 33 10 80 10 47 0 80 4 80 10 0 6 -33 10 -80 10 l-80 0 0 30 0 30
-80 0 c-75 0 -80 -1 -80 -21 0 -21 -3 -22 -61 -15 -34 3 -88 6 -120 6 -47 0
-59 3 -59 16 0 12 9 15 38 12 33 -3 37 0 40 21 4 24 4 24 -75 19 -43 -3 -66
-3 -50 -1 15 2 27 9 27 14 0 11 -48 11 -78 -2z m658 -224 c0 -11 -11 -15 -40
-15 -29 0 -40 4 -40 15 0 11 11 15 40 15 29 0 40 -4 40 -15z m190 -46 c0 -10
-6 -19 -14 -19 -19 0 -28 14 -20 33 8 21 34 10 34 -14z m0 -33 c0 -2 -7 -6
-15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m-2430 -71 c0 -8
-4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m2327 -11 c2
-7 -6 -10 -22 -7 -14 3 -25 10 -25 15 0 13 43 6 47 -8z m-2404 -49 c-7 -21
-13 -19 -13 6 0 11 4 18 10 14 5 -3 7 -12 3 -20z m2342 -3 c2 -29 -19 -22 -23
7 -3 15 1 22 9 19 7 -2 13 -14 14 -26z m-5 -97 c-27 -14 -36 -13 -25 4 3 6 16
11 28 11 21 -1 21 -1 -3 -15z m-100 1 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4
0 6 5 11 10 11 6 0 10 -2 10 -4z m-60 -66 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11
10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-3040 -324 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m1010 -506 c12 0 17 -5 14 -14
-5 -12 -32 -9 -72 10 -1 1 0 8 4 17 5 15 8 15 21 2 8 -8 23 -15 33 -15z m-45
-30 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z
m185 -26 c0 -2 -11 -4 -25 -4 -14 0 -25 4 -25 9 0 4 11 6 25 3 14 -2 25 -6 25
-8z"/>
<path d="M8476 7723 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M8417 7723 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"/>
<path d="M8430 7680 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M8340 7655 c-20 -24 -6 -28 15 -5 10 11 13 20 8 20 -6 0 -16 -7 -23
-15z"/>
<path d="M8280 7539 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6881 7474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2156 7267 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2225 7270 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2223 7223 c-19 -7 -16 -16 10 -32 15 -9 18 -9 10 -1 -6 7 -9 19 -6
26 5 15 5 15 -14 7z"/>
<path d="M2268 7223 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2320 7210 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4481 7184 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2270 7160 c6 -11 16 -20 22 -20 6 0 3 9 -7 20 -10 11 -20 20 -22 20
-2 0 1 -9 7 -20z"/>
<path d="M3280 7130 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10
-25 0 -45 -4 -45 -10z"/>
<path d="M3515 7130 c-4 -6 25 -10 79 -10 51 0 86 4 86 10 0 6 -33 10 -79 10
-44 0 -83 -4 -86 -10z"/>
<path d="M4561 7074 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4595 7050 c-4 -6 5 -10 20 -10 15 0 24 4 20 10 -3 6 -12 10 -20 10
-8 0 -17 -4 -20 -10z"/>
<path d="M4680 7006 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M5658 6443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5608 6413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6605 4830 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M6535 4820 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M6495 4784 c-11 -13 -30 -29 -42 -35 -12 -7 -20 -16 -17 -21 3 -4 -4
-8 -15 -8 -12 0 -21 -4 -21 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5
-5 -10 -11 -10 -5 0 -36 -17 -67 -38 -31 -21 -72 -45 -89 -53 -18 -8 -33 -18
-33 -22 0 -5 -4 -6 -9 -2 -5 3 -12 1 -15 -4 -10 -16 -191 -99 -274 -127 -42
-14 -83 -27 -91 -30 -31 -10 -4 -18 149 -45 262 -45 479 -45 778 0 34 5 68 14
76 20 7 6 16 8 19 4 4 -3 26 -1 49 6 132 39 154 46 162 53 6 4 17 8 27 8 14 0
37 23 145 142 38 42 28 45 -12 4 -19 -20 -40 -34 -45 -31 -5 4 -9 2 -9 -4 0
-15 -77 -5 -107 15 -16 10 -23 19 -15 22 18 6 4 22 -19 22 -12 0 -19 -7 -19
-20 0 -21 -15 -27 -25 -10 -3 6 -1 10 5 10 6 0 8 5 5 10 -3 6 -14 10 -23 10
-10 0 -24 9 -31 19 -11 15 -32 21 -93 26 -66 6 -81 11 -97 31 -10 13 -28 24
-40 24 -14 0 -21 6 -21 20 0 16 -7 20 -35 20 -22 0 -38 -6 -44 -17 -10 -17
-10 -17 -11 0 0 20 -4 21 -30 7 -11 -6 -21 -6 -25 -1 -3 5 3 12 14 16 23 7 35
32 18 38 -7 2 -21 -6 -32 -19z m590 -264 c-3 -5 -13 -10 -21 -10 -8 0 -12 5
-9 10 3 6 13 10 21 10 8 0 12 -4 9 -10z"/>
<path d="M6748 4683 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M7240 4669 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2360 2536 c0 -372 4 -394 77 -476 55 -61 126 -96 226 -112 169 -26
333 38 401 155 39 67 46 139 46 459 l0 298 -104 0 -105 0 -3 -327 c-3 -372 -4
-375 -83 -413 -54 -26 -110 -25 -165 3 -79 39 -80 44 -80 415 l0 322 -105 0
-105 0 0 -324z"/>
<path d="M3250 2405 l0 -455 300 0 300 0 0 80 0 80 -195 0 -195 0 0 375 0 375
-105 0 -105 0 0 -455z"/>
<path d="M3790 2780 l0 -79 118 -3 117 -3 3 -372 2 -373 103 0 102 0 0 372 0
373 117 3 116 3 4 79 3 80 -342 0 -343 0 0 -80z"/>
<path d="M4560 2405 l0 -455 105 0 105 0 0 175 0 175 75 0 c89 0 138 -18 158
-57 8 -15 17 -75 21 -132 3 -57 12 -116 19 -130 12 -25 15 -26 125 -29 72 -2
112 1 112 8 0 5 -4 10 -8 10 -19 0 -35 61 -41 165 -9 138 -31 186 -104 223
l-47 25 27 17 c45 28 90 82 103 124 25 79 -5 190 -67 247 -75 70 -160 89 -405
89 l-178 0 0 -455z m385 281 c41 -18 65 -61 65 -119 0 -77 -46 -107 -166 -107
l-74 0 0 120 0 120 70 0 c39 0 86 -6 105 -14z"/>
<path d="M5570 2733 c-23 -71 -88 -272 -146 -449 -57 -176 -104 -323 -104
-327 0 -4 48 -7 108 -7 l107 0 17 63 c9 34 21 74 27 90 l11 27 135 0 c100 0
137 -3 140 -12 2 -7 14 -48 26 -90 l22 -78 108 0 c60 0 109 2 109 4 0 2 -40
124 -88 272 -48 148 -115 351 -147 452 l-60 182 -112 0 -112 0 -41 -127z m234
-419 l6 -24 -85 0 c-47 0 -85 3 -85 7 0 7 68 241 77 263 5 12 65 -157 87 -246z"/>
<path d="M6110 2773 c15 -49 72 -223 125 -388 54 -165 107 -330 118 -367 l20
-68 114 0 c82 0 115 3 118 13 8 25 206 637 245 757 22 69 40 128 40 133 0 4
-48 6 -107 5 l-107 -3 -91 -335 c-50 -184 -94 -342 -97 -350 -5 -13 -7 -12 -7
2 -1 9 -37 148 -80 310 -44 161 -85 312 -91 336 l-11 42 -108 0 -109 0 28 -87z"/>
<path d="M6920 2780 l0 -79 118 -3 117 -3 3 -372 2 -373 100 0 100 0 0 375 0
375 120 0 120 0 0 80 0 80 -340 0 -340 0 0 -80z"/>
<path d="M7692 2518 c3 -335 4 -344 26 -390 47 -96 140 -158 269 -180 137 -23
298 24 373 109 71 81 73 97 78 471 l3 332 -105 0 -105 0 -3 -327 c-3 -306 -4
-330 -23 -361 -60 -100 -247 -91 -291 14 -11 26 -14 103 -14 354 l0 320 -105
0 -106 0 3 -342z"/>
<path d="M5290 1490 c0 -127 2 -150 15 -150 13 0 15 23 15 150 0 127 -2 150
-15 150 -13 0 -15 -23 -15 -150z"/>
<path d="M5390 1490 c0 -127 2 -150 15 -150 13 0 15 23 15 150 0 127 -2 150
-15 150 -13 0 -15 -23 -15 -150z"/>
<path d="M4155 1608 c-29 -17 -44 -40 -45 -65 0 -26 49 -69 90 -78 27 -6 60
-37 60 -57 0 -17 -34 -38 -61 -38 -20 0 -39 9 -55 27 -18 19 -28 23 -35 16
-15 -15 29 -59 65 -69 55 -14 116 24 116 71 0 33 -41 71 -85 79 -74 14 -83 96
-10 96 26 0 39 -6 51 -25 16 -24 44 -34 44 -16 0 6 -10 22 -22 36 -26 30 -83
41 -113 23z"/>
<path d="M5019 1608 c-4 -47 -3 -88 3 -88 8 0 28 57 28 81 0 21 -29 27 -31 7z"/>
<path d="M6280 1525 c0 -88 1 -95 20 -95 19 0 20 7 20 95 0 88 -1 95 -20 95
-19 0 -20 -7 -20 -95z"/>
<path d="M4366 1529 c-14 -11 -26 -25 -26 -30 0 -14 26 -11 40 6 25 30 80 12
80 -27 0 -14 -8 -18 -34 -18 -51 0 -85 -16 -92 -44 -13 -53 40 -91 94 -66 18
8 28 8 30 1 2 -6 10 -11 18 -11 11 0 14 18 14 83 0 88 -3 98 -37 116 -32 16
-57 14 -87 -10z m92 -110 c4 -24 -25 -49 -59 -49 -24 0 -29 4 -29 25 0 13 8
29 18 34 24 15 66 9 70 -10z"/>
<path d="M4530 1543 c0 -4 16 -50 36 -102 31 -82 34 -98 23 -123 -7 -17 -19
-28 -30 -28 -11 0 -19 -7 -19 -15 0 -18 29 -19 52 -1 15 11 107 242 108 269 0
4 -8 7 -18 7 -14 0 -24 -18 -42 -82 l-24 -83 -25 80 c-18 55 -31 81 -43 83
-10 2 -18 0 -18 -5z"/>
<path d="M4810 1543 c0 -5 16 -50 36 -102 31 -82 34 -98 23 -123 -7 -17 -19
-28 -30 -28 -11 0 -19 -7 -19 -15 0 -21 36 -19 57 4 20 21 108 260 99 268 -15
16 -36 -13 -55 -76 -12 -39 -23 -71 -25 -71 -3 0 -14 34 -27 75 -17 58 -26 75
-40 75 -11 0 -19 -3 -19 -7z"/>
<path d="M5109 1519 c-23 -25 -29 -40 -29 -76 0 -76 70 -125 131 -93 32 17 22
28 -21 23 -45 -6 -65 6 -73 42 -4 24 -3 25 59 25 l64 0 0 30 c0 39 -39 80 -76
80 -16 0 -37 -11 -55 -31z m91 -20 c14 -25 12 -27 -20 -30 -42 -4 -60 0 -60
14 0 38 62 50 80 16z"/>
<path d="M5530 1537 c-30 -15 -50 -52 -50 -92 0 -89 92 -135 149 -74 42 46 32
138 -19 164 -35 18 -48 18 -80 2z m72 -34 c20 -18 25 -82 8 -114 -13 -24 -62
-25 -81 -2 -8 9 -14 35 -14 58 0 68 44 97 87 58z"/>
<path d="M5693 1536 c3 -8 11 -34 17 -58 24 -93 42 -138 56 -138 8 0 14 5 14
11 0 19 43 154 46 144 47 -167 64 -190 84 -112 6 23 18 64 26 91 18 62 18 76
0 76 -10 0 -21 -26 -33 -81 l-19 -80 -23 80 c-14 48 -29 81 -37 81 -10 0 -41
-82 -59 -155 -1 -5 -10 27 -20 73 -15 69 -21 82 -38 82 -12 0 -17 -5 -14 -14z"/>
<path d="M5980 1360 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
<path d="M6080 1360 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
<path d="M6180 1360 c0 -13 7 -20 18 -20 11 0 18 7 18 20 0 13 -7 20 -18 20
-11 0 -18 -7 -18 -20z"/>
<path d="M6280 1360 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
